<template>
  <div>
    <a-row :gutter="[16, 16]" class="mt-4">
      <a-col :span="6">
        <div @click.prevent="toDetail('pencapaian', 'prestasi')">
          <card-counter
            typeCard="primary"
            title="Murid Berprestasi"
            :valueCard="dataAchievement.count_all_murid_with_prestasi"
            isDetail
            :filterField="{ prestasi: true }"
          />
        </div>
      </a-col>
      <a-col :span="6">
        <card-counter
          typeCard="warning"
          title="Murid Tanpa Prestasi"
          :valueCard="dataAchievement.count_all_murid_without_prestasi"
        />
      </a-col>
      <a-col :span="6">
        <div
          @click.prevent="toDetail('pencapaian', 'beasiswa')"
        >
          <card-counter
            typeCard="info"
            title="Murid Memiliki Beasiswa"
            :valueCard="dataAchievement.count_all_murid_with_beasiswa"
            isDetail
            :filterField="{ beasiswa: true }"
          />
        </div>
      </a-col>
      <a-col :span="6">
        <card-counter
          typeCard="danger"
          title="Murid Tanpa Beasiswa"
          :valueCard="dataAchievement.count_all_murid_without_beasiswa"
        />
      </a-col>
      <a-col :span="16">
        <div class="border rounded p-3">
          <h4 class="mb-4">Jenis Prestasi Yang Dimiliki Murid</h4>
          <bar-chart
            :labels="mappedJenisPrestasi"
            :data="dataAchievement.data_chart_jenis_prestasi"
            :options="jenisPrestasiDataSet.options"
          />
        </div>
      </a-col>
      <a-col :span="8">
        <div class="border rounded p-3">
          <h4 class="mb-4">Tingkat Prestasi Yang Dimiliki Murid</h4>
          <div class="table-responsive">
            <table class="table table-borderless text-gray-6 mb-0">
              <tbody>
                <tr
                  v-for="item in dataAchievement.data_list_tingkat_prestasi"
                  :key="item.nama"
                >
                  <td class="pl-0 font-weight-bold">
                    <p>{{ item.nama }}</p>
                  </td>
                  <td class="text-right">
                    <p class="font-weight-bold">{{ item.count }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="border rounded p-3 mt-4">
          <h4 class="mb-4">Murid Paling Berprestasi</h4>
          <template v-if="dataAchievement.murid_paling_berprestasi">
            <p class="text-primary font-weight-bold mt-3">
              {{ dataAchievement.murid_paling_berprestasi.nama }}
            </p>
            <p class="mt-2">
              Telah meraih prestasi terbanyak, <br />
              yaitu sebanyak
              {{ dataAchievement.murid_paling_berprestasi.count }} prestasi
            </p>
          </template>
          <template v-else>
            <p class="text-warning font-weight-bold mt-3">
              Sangat disayangkan tidak ada <br />
              murid yang paling berprestasi <br />
              di tahun ajaran ini.
            </p>
          </template>
        </div>
      </a-col>
      <a-col :span="24">
        <div class="border rounded p-3">
          <h4 class="mb-4">Jenis Beasiswa Yang Dimiliki Murid</h4>
          <bar-chart
            :labels="mappedJenisBeasiswa"
            :data="dataAchievement.data_chart_jenis_beasiswa"
            :options="jenisBeasiswaDataSet.options"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { jenisPrestasiList, tingkatPrestasiList, jenisBeasiswaList } from '@/helpers/listsPpdbSelect'
const CardCounter = () => import('@/components/app/shared/CardCounter')
const jenisPrestasiDataSet = {
  data: [14, 58, 123, 23],
  options: {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  },
}
const jenisBeasiswaDataSet = {
  data: [23, 82, 12, 65, 23],
  options: {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  },
}
const kota = ['Jakarta', 'Bekasi', 'Bogor', 'Tanggerang', 'Banten']
const BarChart = () => import('@/components/app/shared/ChartJS/Bar')
export default {
  components: {
    BarChart,
    CardCounter,
  },
  props: {
    dataAchievement: {
      type: Object,
      required: true,
    },
    tahunMasuk: {
      type: String,
    },
  },
  data() {
    return {
      jenisPrestasiDataSet,
      jenisBeasiswaDataSet,
      jenisPrestasiList,
      jenisBeasiswaList,
      tingkatPrestasiList,
      kota,
    }
  },
  methods: {
    handleMenuClick(e) {
      this.schoolYearActive = e.key
    },
    formatValue(value) {
      return `${Number(value).toFixed(0)}`
    },
    toDetail(key, value) {
      this.$router.push({ name: 'Detail Student Candidate Admin', query: { [key]: value, tahun_masuk: this.tahunMasuk } })
    },
  },
  computed: {
    mappedTingkatPrestasi() {
      return this.tingkatPrestasiList.map(row => {
        return {
          ...row,
          total: Math.floor(Math.random() * (200 - 5 + 1)) + 5,
        }
      })
    },
    mappedJenisPrestasi() {
      return this.jenisPrestasiList.map(row => row.title)
    },
    mappedJenisBeasiswa() {
      return this.jenisBeasiswaList.map(row => row.title)
    },
  },
}
</script>

<style>
</style>
